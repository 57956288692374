import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Home from "../../layouts/Home/"
import {graphql, useStaticQuery} from 'gatsby';
import {practicalFunctionsEn} from '../../data/home/functions';
import {featuresEn} from '../../data/home/features';

const IndexPageEn = () => {
  const data = useStaticQuery(graphql`
    query indexHeaderImageEn {
      image: file(relativePath: { eq: "home/header.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <Layout 
    lang="en-CA" 
    switchLink='/' 
    slider={true} 
    headerImage={data.image.childImageSharp} 
    title="Content-driven digital signage business" 
    subTitle="Digital signage allows you to communicate in an easy, efficient, and creative way with your customers as well as your staff." 
    anchorLink="#home-features-section"
    anchorLinkText="Learn more">
      <Seo title="Content-Driven Digital Signage Agency"
           description="Attractif, a digital signage agency. Dynamic on-screen signage allows you to communicate simply, efficiently, and creatively with your customers and employees, across Canada."
           lang="en-CA"/>
      <Home lang="en-CA" practicalFunctions={practicalFunctionsEn} features={featuresEn}/>
    </Layout>
  )
}

export default IndexPageEn
